/** Services */
import api from '../../api';

/** Types */
import { ApiSuccessResponse } from '../../types';
import {
  ViewResponse,
  ViewIsPosResponse,
  UpdateResponse,
  UpdateBranchParams,
  UpdatePaymentTypeResponse,
  UpdatePaymentTypesParams,
  ChannelSalesListResponse,
  UpdateCouvertParams,
  UpdateServiceChargeParams,
  QrMenuUpdate,
} from './types';
import { FormDataType } from '../../../../pages/CustomerDisplay/types';

type ViewRes = ApiSuccessResponse<ViewResponse>;
const view = (active_branch_id: string) =>
  api.get<ViewRes>(`/branches/${active_branch_id}/settings`);

type ChannelSalesListRes = ApiSuccessResponse<ChannelSalesListResponse>;
const getChannelSales = () => api.get<ChannelSalesListRes>(`/sales-channels`);

const getTimeZones = () => api.get('/timezones');

const createChannelSales = (name: string) =>
  api.post(`/sales-channels`, {
    name,
  });

const updateChannelSales = (id: string, name: string) =>
  api.put(`/sales-channels/${id}`, {
    name,
  });

const removeChannelSales = (id: string) => api.delete(`/sales-channels/${id}`);

const qrMenuTemplates = () => api.get(`/qr-menu-settings`);

const setQrMenuTemplates = (id: string, params: QrMenuUpdate) =>
  api.post(`/qr-menu-settings/${id}`, params);

const downloadQrMenuTemplates = (type: string) =>
  api.get(`/qr-menu-template/${type}`);

type ViewIsPosRes = ApiSuccessResponse<ViewIsPosResponse>;
const viewIsOpen = (is_pos: boolean) =>
  api.get<ViewIsPosRes>(`/payment-types`, { params: { filter: { is_pos } } });

type UpdateRes = ApiSuccessResponse<UpdateResponse>;
const update = (params: UpdateBranchParams) =>
  api.post<UpdateRes>(`/branches/${params.active_branch_id}/settings`, params);

type UpdatePaymentTypeRes = ApiSuccessResponse<UpdatePaymentTypeResponse>;
const updatePaymentTypes = (params: UpdatePaymentTypesParams) => {
  const data = new URLSearchParams();
  params.paymentTypes.forEach(item =>
    data.append(
      `payment_types[${item.id}][is_active]`,
      JSON.stringify(item.active ? 1 : 0),
    ),
  );

  return api.put<UpdatePaymentTypeRes>(
    `/branches/${params.active_branch_id}/settings/payment-types`,
    data,
  );
};

const updateCouvert = (params: UpdateCouvertParams) =>
  api.put(`/couvert`, params);

const getCouvert = () => api.get(`/couvert`);

const updateServiceCharge = (params: UpdateServiceChargeParams) =>
  api.put(`/service-charge`, params);

const getServiceCharge = () => api.get(`/service-charge`);

const getDisplayScreen = () => api.get(`/customer-display-screen`);

const updateDisplayScreen = (params: any) =>
  api.put(`/customer-display-screen`, params);

export default {
  view,
  getChannelSales,
  createChannelSales,
  updateChannelSales,
  removeChannelSales,
  qrMenuTemplates,
  update,
  viewIsOpen,
  updatePaymentTypes,
  setQrMenuTemplates,
  updateCouvert,
  getCouvert,
  updateServiceCharge,
  getServiceCharge,
  getTimeZones,
  getDisplayScreen,
  updateDisplayScreen,
  downloadQrMenuTemplates,
};
