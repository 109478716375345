import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import useSnackbarService from '../hooks/useSnackbarService';
import Dashboard from '../pages/Dashboard';
import Users from '../pages/UserOperations/Users';
import Definitions, {
  Categories,
  Customers,
  Ingredients,
  Properties,
  Tables,
} from '../pages/definitions';
import Product, { ProductWizard } from '../pages/definitions/Product';
import Login from '../pages/Login';
import NotFound404 from '../pages/NotFound404';
import Register from '../pages/Register';
import Profile from '../pages/Profile';
import Root from '../pages/Root';
import CompanyRegister from '../pages/CompanyRegister';
import CompanyRegistrationRequired from './CompanyRegistrationRequired';
import CustomerDetails, {
  CustomerDetailWizard,
} from '../pages/definitions/CustomerDetails';
import Pos from '../apps/Pos';
import Kitchen from '../apps/kitchen';
import Sections from '../apps/Pos/Sections/Sections';
import OrderModal from '../apps/Pos/Sections/OrderModal';
import RestaurantSettings from '../pages/Settings/RestaurantSettings';
import PrinterSettings from '../pages/Settings/PrinterSettings';
import Lock from '../pages/Lock';
import Expenses from '../pages/Operations';
import Permissions from '../pages/UserOperations/Permissions';
import PermissionDetails, {
  PermissionDetailsWizard,
} from '../pages/UserOperations/PermissionDetails';
import EndOfDayDetails, {
  EndOfDayWizard,
} from '../pages/Reports/EndOfDayDetails';
import EndOfDay from '../pages/Reports/EndOfDay';
import OperationLogsDetails, {
  OperationLogsWizard,
} from '../pages/Reports/OperationLogs';
import Orders from '../apps/Pos/Orders';
import useSettings from '../hooks/useSettings';
import Apps from '../pages/Apps';
import PaymentHistory from '../pages/Settings/PaymentHistory';
import QrMenu from '../pages/QrMenu';
import BulkProduct from '../pages/BulkProduct';
import FraudTracking from '../pages/Operations/FraudTracking';
import WaiterSales from '../pages/Reports/WaiterSales';
import SectionSales from '../pages/Reports/SectionSales';
import ProductSales from '../pages/Reports/ProductSales';
import CategorySales from '../pages/Reports/CategorySales';
import CallerIdRecords from '../pages/definitions/CallerIdRecords';
import ForgotPassword from '../pages/ForgotPassword';
import CouvertSettings from '../pages/CouvertSettings';
import ServiceChargeSettings from '../pages/ServiceChargeSettings';
import TrendyolIntegration from '../pages/Integrations/TrendyolIntegration';
import CustomerDisplay from '../pages/CustomerDisplay';
import CustomerDisplayScreen from '../pages/CustomerDisplay/CustomerDisplayScreen';

function Navigation() {
  useSnackbarService();
  const { meProfile } = useSettings();

  return (
    <BrowserRouter>
      <Routes>
        {/* root routes */}
        <Route path="/" element={<Root variant="permanent" />}>
          <Route path="company-register" element={<CompanyRegister />} />
          <Route path="company-register-otp" element={<CompanyRegister />} />
          {/* main routs */}
          <Route element={<CompanyRegistrationRequired />}>
            <Route index element={<Dashboard />} />

            <Route path="/apps" element={<Apps />} />
            <Route
              path="/integrations/trendyol"
              element={<TrendyolIntegration />}
            />
            <Route
              path="/operations/customer-display-screen"
              element={<CustomerDisplay />}
            />
            <Route path="/qr-menu" element={<QrMenu />} />
            <Route path="/operations/couvert" element={<CouvertSettings />} />
            <Route
              path="/operations/service-charge"
              element={<ServiceChargeSettings />}
            />

            <Route path="/profile" element={<Profile />} />
            <Route path="/bulk-product" element={<BulkProduct />} />

            <Route path="definitions" element={<Definitions />}>
              <Route path="tables" element={<Tables />} />
              <Route path="categories" element={<Categories />} />
              <Route path="product/:catId" element={<Product />}>
                <Route index element={<ProductWizard />} />
                <Route path=":productId" element={<ProductWizard />} />
              </Route>
              <Route path="customers" element={<Customers />} />
              <Route path="customer-details" element={<CustomerDetails />}>
                <Route index element={<CustomerDetailWizard />} />
                <Route path=":customerId" element={<CustomerDetailWizard />} />
              </Route>
              <Route path="properties" element={<Properties />} />
              <Route path="ingredients" element={<Ingredients />} />
            </Route>

            <Route path="operations/stock" element={<Dashboard />} />
            <Route path="operations/expense" element={<Expenses />} />
            <Route path="operations/wastage" element={<Dashboard />} />
            <Route
              path="operations/fraud-tracking"
              element={<FraudTracking />}
            />
            <Route
              path="operations/caller-id-records"
              element={<CallerIdRecords />}
            />

            <Route path="reports/end-of-day" element={<EndOfDay />} />
            <Route path="reports/waiter-sales" element={<WaiterSales />} />
            <Route path="reports/section-sales" element={<SectionSales />} />
            <Route path="reports/product-sales" element={<ProductSales />} />
            <Route path="reports/category-sales" element={<CategorySales />} />
            <Route
              path="reports/operation-logs-details"
              element={<OperationLogsDetails />}
            >
              <Route index element={<OperationLogsWizard />} />
              <Route path=":dayId" element={<OperationLogsWizard />} />
            </Route>
            <Route
              path="reports/end-of-day-details"
              element={<EndOfDayDetails />}
            >
              <Route index element={<EndOfDayWizard />} />
              <Route path=":dayId" element={<EndOfDayWizard />} />
            </Route>
            <Route path="reports/stock" element={<Dashboard />} />
            <Route path="reports/statistics" element={<Dashboard />} />

            <Route path="user-operations/users" element={<Users />} />
            <Route path="user-operations/role-list" element={<Permissions />} />
            <Route path="user-operations/roles" element={<PermissionDetails />}>
              <Route index element={<PermissionDetailsWizard />} />
              <Route path=":roleId" element={<PermissionDetailsWizard />} />
            </Route>

            <Route
              path="settings/restaurant"
              element={<RestaurantSettings />}
            />
            <Route path="settings/printer" element={<PrinterSettings />} />
            <Route
              path="settings/payment-history"
              element={<PaymentHistory />}
            />

            {/* apps routs */}
            <Route path="app/pos" element={<Pos />}>
              <Route
                index
                element={
                  meProfile?.workingTypes?.find(item => item.id === 1) ? (
                    <Sections />
                  ) : (
                    <Orders />
                  )
                }
              />
              <Route path="sections" element={<Sections />} />
              <Route path="orders" element={<Orders />} />
              <Route path="order/package" element={<OrderModal />} />
              <Route path="order/package/:id" element={<OrderModal />} />
              <Route path="order/self" element={<OrderModal />} />
              <Route path="order/self/:id" element={<OrderModal />} />
              <Route path="order/:id" element={<OrderModal />} />
              <Route path="sections/order/:id" element={<OrderModal />} />
            </Route>
            <Route path="app/kitchen" element={<Kitchen />} />
          </Route>
        </Route>

        {/* auth routs */}
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password/:token" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ForgotPassword />} />
        <Route path="/lock" element={<Lock />} />
        <Route path="/register" element={<Register />} />
        <Route path="/customer-display" element={<CustomerDisplayScreen />} />
        <Route path="*" element={<NotFound404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Navigation;
