import { SxProps, Theme } from '@mui/material';

const styles: Record<string, SxProps<Theme>> = {
  container: {
    display: 'flex',
    flexDirection: { md: 'row', xs: 'column' },
    minHeight: '100vh',
  },
  leftContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    background: '#eff1f4',
  },
  rightContainer: {
    flex: 2,
    py: { md: 15, xs: 5 },
    px: { lg: 10, xs: 5 },
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    pt: 10,
    pb: 8,
    px: 2,
    mx: 'auto',
    flex: 1,
    textAlign: 'center',
    minWidth: 400,
  },
  logoSubtitle: { mt: 4, color: 'text.secondary' },
  promotion: { flex: 1, py: 2, display: { md: 'block', xs: 'none' } },
  formContainer: {
    width: { lg: 500, md: 400, sm: 500 },
  },
  submit: {
    mt: 5,
    height: 45,
  },
  field: {
    my: 0,
  },
};

export default styles;
